import React, { useRef, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
// styles
import styles from './styles.module.scss';
// types
import { ITabMenuOptionProps } from './types';
import { ScheduleMeetingRoomContext } from '@/utils/helpers';
import { keyboardAccessible } from '@/utils/keyboard accessible';

const TabMenuOption = (props: ITabMenuOptionProps) => {
  const {
    active,
    children,
    id,
    index,
    menuType = '',
    onClick,
    isPublishedPollTab,
    textColor,
  } = props;

  const { isMyMeetingRoomsModalView } = useContext(ScheduleMeetingRoomContext);
  const tabName = children[0];

  const [isTabFocusable, setIsTabFocusable] = useState(false);

  useEffect(() => {
    if(
      tabName === 'Stage' ||
      tabName === 'Backstage' || 
      tabName === 'Event' ||
      tabName === 'Drafts' || 
      tabName === 'Published' ||
      tabName === 'Session'
    ) {
      setIsTabFocusable(true);
    }
  }, [tabName])
  const containerRef = useRef(null);
  return (
    <div
      tabIndex={isTabFocusable ? 0 : -1}
      role="tab"
      aria-selected={isTabFocusable ? `${active}` : ''}
    className={classnames('elementAsButton',
      styles[`${menuType}TabMenuOptionContainer`],
      { [styles[`${menuType}Active`]]: active },
      { [styles[`${menuType}Inactive`]]: !active },
      { [styles.dimInactive]: !active },
      { [styles.firstTab]: index === 0 },
      { [styles.publishedPollMenuTab]: isPublishedPollTab && index === 1 },
      { [styles.modalItemBgColor]: isMyMeetingRoomsModalView },
      )}
      style={textColor ? { color: textColor } : {}}
      id={id}
      onClick={() => onClick(index)}
      onKeyDown={(e: any) => keyboardAccessible(e, () => onClick(index))}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

export default TabMenuOption;